@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    @apply h-full m-0 p-0;
}

#root {
    @apply h-full;
}

:root {
    --color-primary: 59 130 246;
}

h1 {
}

h2 {
}

h3 {
    @apply m-0;
}

h4,
h5,
h6 {
    @apply font-sans font-medium;
}

h4 {
    @apply text-2.5xl;
    @apply leading-[2.4375rem];
}

h5 {
    @apply text-2xl;
    @apply leading-[2.125rem];
}

h6 {
    @apply text-xl;
    @apply leading-7;
}

.react-pdf__message--loading {
    @apply h-full;
}
